import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Button, Video } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="হাদীস শিখি ইউশার সাথে" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  হাদীস শিখি ইউশার সাথে
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <img src="/assets/images/question-answer.png" alt="হাদীস শিখি ইউশার সাথে" />
      <img src="/assets/images/fill-in-the-blanks.png" alt="হাদীস শিখি ইউশার সাথে" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      